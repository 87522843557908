<template>
  <div class="mt-1 mx-3">
    <coinsurance></coinsurance>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Coinsurance from "@/components/accounting/insurer-participant/index.vue";

export default {
  props: {},
  components: { Coinsurance },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  created() {
    this.notifyQueue({
      text: "Contabilità",
      path: "",
      level: 1,
    });
    this.notifyQueue({
      text: "Coassicurazioni",
      path: "module.CONT.COLL",
      level: 2,
    });
  },
};
</script>
