<template>
  <div class="mt-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-datepicker
                name="book_date"
                label="Data Registrazione Dal"
                v-model="filter.byCalendar.from"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="book_date"
                label="Data Registrazione al"
                v-model="filter.byCalendar.to"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name=""
                label="Coassicurazione"
                v-model="filter.byInsurerParticipant.id"
                :options="opt_insurer_participant"
                @input="onInputInsurerParticipants"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
          <b-button-group
            v-b-toggle:collapse-1-inner
            class="my-2 filter-button-group"
          >
            <span class="when-open">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            <span class="when-closed">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            Altri Filtri
          </b-button-group>

          <b-collapse id="collapse-1-inner" class="mt-2">
            <b-row> </b-row>
            <div v-show="customFilters.length > 0">
              <p>Attributi personalizzati</p>

              <b-row v-for="(element, index) in customFilters" :key="index">
                <custom-filter
                  v-model="filter.byAttribute[element.key]"
                  :element="element"
                />
              </b-row>
            </div>
          </b-collapse>
        </b-card>
      </b-form>
    </b-collapse>
    <insurer-participants-accounting
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterOn="{
        byRelations: [
          'byBroker',
          'byRegistry',
          'byInsurer',
          'byInsuranceAncillary',
          'byVariousAccounting',
        ],
      }"
      :filterName="filterName"
      :onlyActions="['infomodal']"
      :ref="tableRef"
    ></insurer-participants-accounting>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import InsurerParticipantsAccounting from "@/components/tables/InsurerParticipantsAccounting";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import CustomFilter from "@/components/form/CustomFilter";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { mapActions } from "vuex";
import { toLocaleDate } from "@/utils/dates";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      tableRef: "AccountingInsurerParticipantTableRef",
      filter: this.initFilter(),
      filterName: "filterAccountingInsurerParticipant",
      resource: "book_entries",
      repository: "book_entry",
      opt_insurer_participant: [{ text: "Tutti", value: "!0" }],
      fields: [
        {
          key: "book_date",
          label: this.getDictionary("book_date", "book_entry"),
          sortable: true,
          formatter: (value) => toLocaleDate(value),
          sortKey: "book_date",
        },
        {
          key: "insurance_policy_number",
          label: this.getDictionary("insurance_policy_number", "book_entry"),
          sortable: true,
          sortKey: "byInsurancePolicy.number",
        },
        {
          key: "customer",
          label: this.getDictionary("registry", "book_entry"),
          sortable: true,
          sortKey: "byRegistry.title",
        },
        {
          key: "insurers",
          label: this.getDictionary("Compagnie Presenti"),
        },
        {
          key: "gross",
          label: this.getDictionary("Lordi Compagnie"),
          tdClass: "text-right",
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
        },
        {
          key: "participant_prov_purchase",
          label: this.getDictionary("Prov. Acq."),
          tdClass: "text-right",
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
        },
        {
          key: "participant_prov_take",
          label: this.getDictionary("Prov. Inc."),
          tdClass: "text-right",
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
        },
        {
          key: "gross_tot",
          label: this.getDictionary("Totale Coassicurato"),
          tdClass: "text-right",
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
        },
        {
          key: "participant_prov_tot",
          label: this.getDictionary("Provvigioni Totali"),
          tdClass: "text-right",
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
        },
      ],
      coassicurazioni: [],
    };
  },
  components: {
    BaseSelect,
    BaseDatepicker,
    InsurerParticipantsAccounting,
    CustomFilter,
  },
  methods: {
    initFilter() {
      let init = {
        byCalendar: {
          from: null,
          to: null,
        },
        byInsurerParticipant: { id: "!0" },
      };
      return init;
    },
    onInputInsurerParticipants(val) {
      if (!val) {
        this.filter.byInsurerParticipant.id = "!0";
      }
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.$refs[this.tableRef].fetch();
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
  mounted() {
    const Repo = RepositoryFactory.get("insurer_participant");
    Repo.index("perPage=0")
      .then((response) => {
        const data = response.data.data;
        let insurer_participants = data.map((e) => {
          return {
            value: e.id,
            text: `${e.code} - ${e.title}`,
            code: `${e.code}`,
          };
        });
        this.opt_insurer_participant =
          this.opt_insurer_participant.concat(insurer_participants);
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({ preset: "error", text: errMsg });
        this.isLoading = false;
      });
  },
};
</script>
