var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-overlay',{attrs:{"center":"","show":_vm.isLoading,"rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center mt-5"},[_c('base-icon',{attrs:{"name":"loading","width":"35","height":"35"}}),_vm._v(" "),_c('p',{attrs:{"id":"cancel-label"}},[_vm._v("Operazione in corso...")])],1)]},proxy:true}])},[(!_vm.isLoading)?_c('div',[_vm._l((_vm.detailFields),function(item,index){return _c('details-card',{key:index,attrs:{"header":item.header,"edit":item.edit || false,"fields":item.fields,"beForm":_vm.beForm,"data":_vm.details,"repository":item.repository}})}),_vm._v(" "),_c('b-card',{staticClass:"mt-1",attrs:{"header":"Dettaglio Provvigioni"}},[_c('div',{staticClass:"tableFixHead"},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Codice Compagnia")]),_vm._v(" "),_c('th',[_vm._v("Codice Agenzia")]),_vm._v(" "),_c('th',[_vm._v("Descrizione")]),_vm._v(" "),_c('th',[_vm._v("Provv. Acq. Agente")]),_vm._v(" "),_c('th',[_vm._v("Provv. Inc. Agente")]),_vm._v(" "),_c('th',[_vm._v("Imponibile")]),_vm._v(" "),_c('th',[_vm._v("Imposte")]),_vm._v(" "),_c('th',[_vm._v("Totale")])])]),_vm._v(" "),_c('tbody',_vm._l((_vm.details
                  .insurance_ancillaries[0].insurer_participants),function(insurer_participant,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(insurer_participant.code_importer))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(insurer_participant.code))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(insurer_participant.title_importer))]),_vm._v(" "),_c('td',[_c('div',{staticClass:"mt-3"},[_c('base-currency',{attrs:{"name":"participant_prov_purchase","vid":"participant_prov_purchase","options":{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }},model:{value:(
                        insurer_participant.pivot.participant_prov_purchase
                      ),callback:function ($$v) {_vm.$set(insurer_participant.pivot, "participant_prov_purchase", $$v)},expression:"\n                        insurer_participant.pivot.participant_prov_purchase\n                      "}})],1)]),_vm._v(" "),_c('td',[_c('div',{staticClass:"mt-3"},[_c('base-currency',{attrs:{"name":"participant_prov_take","vid":"participant_prov_take","options":{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }},model:{value:(
                        insurer_participant.pivot.participant_prov_take
                      ),callback:function ($$v) {_vm.$set(insurer_participant.pivot, "participant_prov_take", $$v)},expression:"\n                        insurer_participant.pivot.participant_prov_take\n                      "}})],1)]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.toLocaleCurrency(insurer_participant.pivot.net)))]),_vm._v(" "),_c('td',[_vm._v("\n                  "+_vm._s(_vm.toLocaleCurrency(insurer_participant.pivot.tax))+"\n                ")]),_vm._v(" "),_c('td',[_vm._v("\n                  "+_vm._s(_vm.toLocaleCurrency(insurer_participant.pivot.gross))+"\n                ")])])}),0)])]),_vm._v(" "),_c('div',{staticClass:"py-2 mb-4"},[_c('b-button',{attrs:{"type":"button","variant":"lisaweb","size":"sm"},on:{"click":_vm.onUpdate}},[_vm._v("\n            Aggiorna\n          ")])],1)])],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }