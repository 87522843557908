<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <details-card
          v-for="(item, index) in detailFields"
          :key="index"
          :header="item.header"
          :edit="item.edit || false"
          :fields="item.fields"
          :beForm="beForm"
          :data="details"
          :repository="item.repository"
        >
        </details-card>
        <b-card header="Dettaglio Provvigioni" class="mt-1">
          <div class="tableFixHead">
            <table>
              <thead>
                <tr>
                  <th>Codice Compagnia</th>
                  <th>Codice Agenzia</th>
                  <th>Descrizione</th>
                  <th>Provv. Acq. Agente</th>
                  <th>Provv. Inc. Agente</th>
                  <th>Imponibile</th>
                  <th>Imposte</th>
                  <th>Totale</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(insurer_participant, index) in details
                    .insurance_ancillaries[0].insurer_participants"
                  v-bind:key="index"
                >
                  <td>{{ insurer_participant.code_importer }}</td>
                  <td>{{ insurer_participant.code }}</td>
                  <td>{{ insurer_participant.title_importer }}</td>
                  <td>
                    <div class="mt-3">
                      <base-currency
                        name="participant_prov_purchase"
                        vid="participant_prov_purchase"
                        v-model="
                          insurer_participant.pivot.participant_prov_purchase
                        "
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="mt-3">
                      <base-currency
                        name="participant_prov_take"
                        vid="participant_prov_take"
                        v-model="
                          insurer_participant.pivot.participant_prov_take
                        "
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                      />
                    </div>
                  </td>
                  <td>{{ toLocaleCurrency(insurer_participant.pivot.net) }}</td>
                  <td>
                    {{ toLocaleCurrency(insurer_participant.pivot.tax) }}
                  </td>
                  <td>
                    {{ toLocaleCurrency(insurer_participant.pivot.gross) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="py-2 mb-4">
            <b-button
              type="button"
              variant="lisaweb"
              size="sm"
              @click="onUpdate"
            >
              Aggiorna
            </b-button>
          </div>
        </b-card>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import DetailsCard from "@/components/DetailsCard";
import { toInfoData } from "@/utils/transforms";
import { toLocaleCurrency } from "@/utils/strings";
import BaseCurrency from "@/components/form/BaseCurrency";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  mixins: [FormMixin, ShowMixin],
  name: "General",
  props: {
    resourceId: Number,
  },
  components: {
    BaseIcon,
    DetailsCard,
    BaseCurrency,
  },
  data() {
    return {
      details: null,
      repository: "book_entry",
      allRepositories: ["insurer"],
      mandatoryFields: {},
      isLoading: true,
      generalLabels: null,
      defaultLabels: {
        contacts: {},
      },
      detailFields: {
        scheda_contatto: {
          header: "Dettaglio Movimento in Coass",
          edit: false,
          repository: "insurer",
          fields: [
            {
              label: this.getDictionary("number", "insurance_policy"),
              value: "insurance_policy",
              // virtual: (detail) => {
              //   return detail.various_accountings &&
              //     detail.various_accountings.length
              //     ? detail.various_accountings[0].pivot.insurance_risk
              //         .risk_branch.code
              //     : detail.insurance_ancillaries &&
              //       detail.insurance_ancillaries.length
              //     ? detail.insurance_ancillaries[0].insurance_policy
              //       ? detail.insurance_ancillaries[0].insurance_policy
              //           .insurance_risk.risk_branch.code
              //       : ""
              //     : "";
              // },
              virtual: (detail) => {
                return detail.various_accountings &&
                  detail.various_accountings.length
                  ? detail.various_accountings[0].pivot.insurance_policy_number
                  : detail.insurance_ancillaries &&
                    detail.insurance_ancillaries.length
                  ? detail.insurance_ancillaries[0].insurance_policy
                    ? detail.insurance_ancillaries[0].insurance_policy.number
                    : ""
                  : "";
              },
            },
            {
              label: this.getDictionary("registry", "book_entry"),
              value: "registry",
              virtual: (detail) => {
                return detail.registries.length
                  ? detail.registries[0].status_registry.value === 0
                    ? `${detail.registries[0].attributables.SURN} ${detail.registries[0].attributables.NAME}`
                    : `${detail.registries[0].attributables.CNAM}`
                  : "";
              },
            },
            {
              label: this.getDictionary("Totale Coassicurato"),
              value: "",
              virtual: (detail) => {
                return toLocaleCurrency(
                  this.sumPivot(
                    detail.insurance_ancillaries[0].insurer_participants,
                    "gross"
                  )
                );
              },
            },
            {
              label: this.getDictionary("Totale Registrazione"),
              value: "gross",
              type: "currency",
            },
          ],
        },
      },
    };
  },
  methods: {
    toInfoData,
    toLocaleCurrency,
    fetch() {
      let queryString = `relations/byRegistry/byTreasury/byBreakdown/byBookTag/byInsurer/byBroker/byInsuranceAncillary/byVariousAccounting/byInsurerAccounting/byInsurerParticipant`; // #851
      this.fetchShowForm(this.repository, this.resourceId, queryString)
        .then((response) => {
          const data = response.data.data;
          this.details = data;
          this.initDetailCardFormData();
          this.isLoading = false;
        })
        .catch((error) => {
          this.form = {};
          console.error(error);
          this.isLoading = false;
        });
    },
    onUpdate() {
      this.isLoading = true;
      this.addCoassAncillary("insurer_participant")
        .then(() => {
          this.fetch();
          this.$emit("fetch");
          this.$showSnackbar({
            preset: "success",
            text: `Modifica Provvigioni avvenuta con successo`,
          });
        })
        .catch(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Errore durante la procedura`,
          });
        });
    },
    addCoassAncillary(relation) {
      let payload = {
        insurer_participant: {},
      };
      let insuranceAncillaryId;
      // Coass Detail
      this.details.insurance_ancillaries[0].insurer_participants.forEach(
        (e) => {
          insuranceAncillaryId = e.pivot.insurance_ancillary_id;
          payload.insurer_participant[e.id] = {
            share: e.pivot.share || 0,
            net: e.pivot.net || 0,
            tax: e.pivot.tax || 0,
            gross: (e.pivot.net || 0) + (e.pivot.tax || 0),
            participant_prov_purchase: e.pivot.participant_prov_purchase || 0,
            participant_prov_take: e.pivot.participant_prov_take || 0,
          };
        }
      );
      const Repo = RepositoryFactory.get("insurance_ancillary");
      return Repo.pivot_update(insuranceAncillaryId, relation, payload);
    },
    sumPivot(array, field) {
      let initialValue = 0;
      let sum = array.reduce(function (previousValue, currentValue) {
        return previousValue + currentValue.pivot[field];
      }, initialValue);
      return sum;
    },
  },
  mounted() {
    this.isLoading = true;
    this.fetch();
  },
};
</script>

<style scoped>
.tableFixHead {
  overflow: auto;
  height: 200px;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.form-group {
  margin-bottom: 0px;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
  text-align: left;
}
th {
  background: #fff;
}

tr {
  background-color: #fff;
  border-top: 1px solid #dee2e6;
}
</style>
